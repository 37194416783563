/*******************************/
/********* General CSS *********/
/*******************************/
.breadcrumb {
    font-family: avenir-light,sans-serif;
}



body {
    color: #666666;
    background: #ffffff;
    font-family: poppins;
    font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Roboto Condensed';
    font-weight: 600;
    /*font-style: italic;*/
    color: #e53f00;
    word-break: break-word;
}

a {
    color: #454545;
    transition: .3s;
}
b{
    color:black;
}
a:hover,
a:active,
a:focus {
    color: #aa9166;
    outline: none;
    text-decoration: none;
}

.btn:focus {
    box-shadow: none;
}

.wrapper {
    position: relative;
    width: 100%;
    max-width: 1366px;
    margin: 0 auto;
    background: #ffffff;
}

.back-to-top {
    position: fixed;
    display: none;
    background: #37297c;
    color: #ff8260;
    width: 44px;
    height: 44px;
    text-align: center;
    line-height: 1;
    font-size: 22px;
    right: 15px;
    bottom: 15px;
    transition: background 0.5s;
    z-index: 9;
}

.back-to-top:hover {
    color: #aa9166;
    background: #121518;
}

.back-to-top i {
    padding-top: 10px;
}


/**********************************/
/********** Top Bar CSS ***********/
/**********************************/
.top-bar {
    position: relative;
    height: 50px;
    background: #ffffff;
}

.top-bar .logo {
    padding: 15px 0;
    text-align: left;
    overflow: hidden;
}

.top-bar .logo h1 {
    margin: 0;
    color: #aa9166;
    font-size: 75px;
    line-height: 55px;
    font-weight: 800;
}

.top-bar .logo img {
    max-width: 100%;
    max-height: 60px;
}

.top-bar .top-bar-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.top-bar .text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 90px;
    padding: 0 20px;
    text-align: center;
    border-left: 1px solid rgba(255, 255, 255, .15);
}

.top-bar .text h2 {
    color: #ffffff;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 25px;
    margin: 0;
}

.top-bar .text p {
    color: #ffffff;
    font-size: 13px;
    margin: 0;
}

.top-bar .social {
    display: flex;
    height: 90px;
    font-size: 0;
    justify-content: flex-end;
}

.top-bar .social a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    font-size: 14px;
    color: white;
    border-right: 1px solid rgba(255, 255, 255, 0.15);
    border-radius: 50px;
}

.top-bar .social a:first-child {
    border-left: 1px solid rgba(255, 255, 255, .15);
    font-size: 14px;
}

.top-bar .social a:hover {
    color: #1055d7;
    background: #ffffff;
}

@media (min-width: 992px) {
    .top-bar {
        padding: 0 60px;
    }
}

@media (max-width: 991.98px) {
    .top-bar .logo {
        text-align: center;
    }

    .top-bar .social {
        display: none;
    }
}

.nav-bar {
    /*position: sticky;*/
    top: 0;
    padding: 20px 0;
    background: #ffffff;
    transition: .3s;
}

.nav-bar .navbar {
    height: 100%;
    padding: 0;
}

.navbar-dark .navbar-nav .nav-link,
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
    padding: 10px 10px 8px 10px;
    color: black;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
    color: #1055d7;
    transition: none;
}

.nav-bar .dropdown-menu {
    margin-top: 0;
    border: 0;
    border-radius: 0;
    background: #f8f9fa;
}

.nav-bar .btn {
    color: #ffffff;
    border: 2px solid #f1926f;
    border-radius: 0;
}

.nav-bar .btn:hover {
    background: #f1926f;
}

@media (min-width: 992px) {
    .nav-bar {
        padding: 20px 60px;
    }

    .nav-bar.nav-sticky {
        padding: 10px 60px;
    }

    .nav-bar,
    .nav-bar .navbar {
        background: white !important;
    }

    .nav-bar .navbar-brand {
        display: none;
    }

    .nav-bar a.nav-link {
        padding: 8px 15px;
        font-size: 15px;
        text-transform: uppercase;
    }
}

@media (max-width: 991.98px) {
    .nav-bar,
    .nav-bar .navbar {
        padding: 3px 0;
        background: black !important;
        color: white;
    }

    .navbar-dark .navbar-nav .nav-link,
    .navbar-dark .navbar-nav .nav-link:focus,
    .navbar-dark .navbar-nav .nav-link:hover,
    .navbar-dark .navbar-nav .nav-link.active {
        padding: 10px 10px 8px 10px;
        color: white;
    }

    .nav-bar a.nav-link {
        padding: 5px;
    }

    .nav-bar .dropdown-menu {
        box-shadow: none;
    }

    .nav-bar .btn {
        display: none;
    }
}

/*******************************/
/******** Carousel CSS *********/
/*******************************/
.carousel {
    position: relative;
    width: 100%;
    height: calc(100vh - 170px);
    margin: 0 auto;
    text-align: center;
    overflow: hidden;
}

.carousel .carousel-inner,
.carousel .carousel-item {
    position: relative;
    width: 100%;
    height: 100%;
}

.carousel .carousel-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.carousel .carousel-item::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .3);
    z-index: 1;
}

.carousel .carousel-caption {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: calc(100vh - 170px);
}

.carousel .carousel-caption h1 {
    color: #ffffff;
    font-size: 60px;
    font-weight: 700;
    margin-top: 5%;
    text-align:center;
}

.carousel .carousel-caption p {
    color: #ffffff;
    font-size: 25px;
    margin-bottom: 25px;
}

.carousel .carousel-caption .btn {
    padding: 15px 35px;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #aa9166;
    background: #121518;
    border: 2px solid #aa9166;
    border-radius: 0;
    transition: .3s;
}

.carousel .carousel-caption .btn:hover {
    color: #121518;
    background: #aa9166;
}

@media (max-width: 767.98px) {
    .carousel .carousel-caption h1 {
        font-size: 40px;
        font-weight: 700;
    }

    .carousel .carousel-caption p {
        font-size: 20px;
    }

    .carousel .carousel-caption .btn {
        padding: 12px 30px;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
    }
}

@media (max-width: 575.98px) {
    .carousel .carousel-caption h1 {
        font-size: 18px;
        font-weight: 500;
        margin-top: -138%;
    }

    .carousel .carousel-caption p {
        font-size: 16px;
    }

    .carousel .carousel-caption .btn {
        padding: 10px 25px;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0;
    }
}

.carousel .animated {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
}


/*******************************/
/******* Page Header CSS *******/
/*******************************/
.page-header {
    position: relative;
    margin-bottom: 45px;
    padding: 90px 0;
    text-align: center;
    /*background: #ea3322;*/
    /*background-image: url('../img/Ext-Sales-1-1903x700.jpg.webp');*/
    /*background-image: url('../img/aboutbanner.webp');*/
}

.page-header h2 {
    position: relative;
    color: white;
    font-size: 60px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 5px;
}

.page-header h2::after {
    position: absolute;
    content: "";
    width: 100px;
    height: 2px;
    left: calc(50% - 50px);
    bottom: 0;
    background: white;
}

.page-header a {
    position: relative;
    padding: 0 12px;
    font-size: 22px;
    color: white;
}

.page-header a:hover {
    color: #ffffff;
}

.page-header a::after {
    position: absolute;
    content: "/";
    width: 8px;
    height: 8px;
    top: -2px;
    right: -7px;
    text-align: center;
    color: white;
}

.page-header a:last-child::after {
    display: none;
}

@media (max-width: 767.98px) {
    .page-header h2 {
        font-size: 35px;
    }

    .page-header a {
        font-size: 18px;
    }
}


/*******************************/
/******* Section Header ********/
/*******************************/
.section-header {
    position: relative;
    width: 100%;
    text-align: center;
    margin-bottom: 45px;
}

.section-header h2 {
    margin: 0;
    position: relative;
    font-size: 50px;
    font-weight: 700;
    /*white-space: nowrap;*/
    z-index: 1;
}

.section-header h2::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 7px;
    left: 0;
    top: 21px;
    background: transparent;
    /*border-top: 1px solid #aa9166;
    border-bottom: 1px solid #aa9166;*/
    z-index: -1;
}

.section-header h2::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 7px;
    left: 0;
    bottom: 20px;
    background: transparent;
    /*border-top: 1px solid #aa9166;
    border-bottom: 1px solid #aa9166;*/
    z-index: -1;
}

@media (max-width: 767.98px) {
    .section-header h2 {
        font-size: 30px;
    }
}

/*******************************/
/******** Top Feature CSS ******/
/*******************************/
.feature-top {
    position: relative;
    background: #121518;
    margin-bottom: 45px;
}

.feature-top .col-md-3 {
    border-right: 1px solid rgba(255, 255, 255, .1);
    border-bottom: 1px solid rgba(255, 255, 255, .1);
}

@media (max-width: 575.98px) {
    .feature-top .col-md-3:nth-child(1n) {
        border-right: none;
    }

    .feature-top .col-md-3:last-child {
        border-bottom: none;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .feature-top .col-md-3:nth-child(2n) {
        border-right: none;
    }

    .feature-top .col-md-3:nth-child(3n),
    .feature-top .col-md-3:nth-child(4n) {
        border-bottom: none;
    }
}

@media (min-width: 768px) {
    .feature-top .col-md-3 {
        border-bottom: none;
    }

    .feature-top .col-md-3:nth-child(4n) {
        border-right: none;
    }
}

.feature-top .feature-item {
    padding: 30px 0;
    text-align: center;
}

.feature-top .feature-item i {
    color: #aa9166;
    font-size: 35px;
    margin-bottom: 10px;
}

.feature-top .feature-item h3 {
    color: #ffffff;
    font-size: 30px;
    font-weight: 600;
}

.feature-top .feature-item p {
    color: #999999;
    margin: 0;
    font-size: 18px;
}


/*******************************/
/********** About CSS **********/
/*******************************/
.about {
    position: relative;
    width: 100%;
    padding: 45px 0;
}

.about .section-header {
    margin-bottom: 30px;
}

.about .about-img {
    position: relative;
    height: 100%;
    padding: -1px;
    /* background: #121518; */
    /*opacity: 0.6;*/
}

.about .about-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.about .about-text p {
    font-size: 16px;
}

.about .about-text a.btn {
    position: relative;
    margin-top: 15px;
    padding: 15px 35px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #f1926f;
    border: 2px solid #f1926f;
    border-radius: 0;
    background: #37297c;
    transition: .3s;
}

.about .about-text a.btn:hover {
    color: #121518;
    background: #aa9166;
}

@media (max-width: 767.98px) {
    .about .about-img {
        margin-bottom: 30px;
        height: auto;
    }
}


/*******************************/
/********* Service CSS *********/
/*******************************/
.service {
    position: relative;
    width: 100%;
    padding: 45px 0 15px 0;
}

.service .service-item {
    position: relative;
    width: 100%;
    text-align: center;
    background: #37297c;
    margin-bottom: 30px;
    border-radius: 10px;
}

.service .service-icon {
    height: 200px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #37297c;
    border-radius: 10px;
}

.service .service-icon i {
    width: 100px;
    height: 100px;
    padding: 20px 0;
    text-align: center;
    font-size: 60px;
    /*color: #aa9166;
    background: #121518;*/
    transition: .5s;
}

.service .service-item:hover .service-icon i {
    margin-top: -15px;
}

.service .service-item h3 {
    margin: 0;
    padding: 25px 15px 15px 15px;
    font-size: 30px;
    font-weight: 600;
    color: #f1926f;
}

.service .service-item p {
    margin: 0;
    color: white;
    padding: 0 25px 25px 25px;
    font-size: 18px;
}

.service .service-item a.btn {
    position: relative;
    margin-bottom: 30px;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #aa9166;
    border: 2px solid #aa9166;
    border-radius: 0;
    background: #121518;
    transition: .3s;
}

.service .service-item:hover a.btn {
    color: #121518;
    background: #aa9166;
}


/*******************************/
/********* Feature CSS *********/
/*******************************/
.feature {
    position: relative;
    width: 100%;
    padding: 45px 0;
}

.feature .feature-item {
    margin-bottom: 30px;
}

.feature .feature-icon {
    height: 180px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #121518;
}

.feature .feature-icon i {
    width: 100px;
    height: 100px;
    padding: 20px 0;
    text-align: center;
    font-size: 60px;
    color: #aa9166;
    background: #000000;
    transition: .5s;
}

.feature .feature-item:hover .feature-icon i {
    margin-right: -15px;
}

.feature .feature-item h3 {
    position: relative;
    margin-bottom: 15px;
    padding-bottom: 10px;
    font-size: 30px;
    font-weight: 600;
}

.feature .feature-item h3::after {
    position: absolute;
    content: "";
    width: 50px;
    height: 2px;
    left: 0;
    bottom: 0;
    background: #aa9166;
}

.feature .feature-item p {
    margin: 0;
    font-size: 16px;
}

.feature .feature-img {
    position: relative;
    height: 100%;
    padding: 10px;
    background: #121518;
}

.feature .feature-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


/*******************************/
/*********** Team CSS **********/
/*******************************/
.team {
    position: relative;
    width: 100%;
    padding: 45px 0 15px 0;
}

.team .team-item {
    margin-bottom: 30px;
}

.team .team-img {
    position: relative;
}

.team .team-img img {
    width: 100%;
}

.team .team-text {
    position: relative;
    padding: 50px 15px 30px 15px;
    text-align: center;
    background: #121518;
}

.team .team-text h2 {
    font-size: 22px;
    font-weight: 600;
    color: #aa9166;
}

.team .team-text p {
    margin: 0;
    color: #999999;
}

.team .team-social {
    position: absolute;
    width: calc(100% - 60px);
    height: 50px;
    top: -25px;
    left: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #aa9166;
    font-size: 0;
    transition: .5s;
}

.team .team-item:hover .team-social {
    width: 100%;
    left: 0;
}

.team .team-social a {
    display: inline-block;
    margin-right: 15px;
    font-size: 18px;
    text-align: center;
    color: #121518;
    transition: .3s;
}

.team .team-social a:last-child {
    margin-right: 0;
}

.team .team-social a:hover {
    color: #ffffff;
}


/*******************************/
/*********** FAQs CSS **********/
/*******************************/
.faqs {
    position: relative;
    width: 100%;
    padding: 45px 0;
}

.faqs .faqs-img {
    position: relative;
    height: 100%;
    /*padding: 10px;*/
    background: #121518;
}

.faqs .faqs-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media(max-width: 767.98px) {
    .faqs .faqs-img {
        margin-bottom: 30px;
        height: auto;
    }
}

.faqs .card {
    margin-bottom: 15px;
    padding-top: 15px;
    border: none;
    border-radius: 0;
    border-top: 1px solid #aa9166;
}

.faqs .card:last-child {
    margin-bottom: 0;
    padding-bottom: 15px;
    border-bottom: 1px solid #aa9166;
}

.faqs .card-header {
    padding: 0;
    border: none;
    background: #ffffff;
}

.faqs .card-header a {
    display: block;
    width: 100%;
    color: #121518;
    font-size: 18px;
    line-height: 40px;
}

.faqs .card-header a span {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    text-align: center;
    background: #37297c;
    color: #ff804a;
    font-weight: 700;
}

.faqs .card-header [data-toggle="collapse"]:after {
    font-family: 'font Awesome 5 Free';
    content: "\f067";
    float: right;
    color: #121518;
    font-size: 12px;
    font-weight: 900;
    transition: .3s;
}

.faqs .card-header [data-toggle="collapse"][aria-expanded="true"]:after {
    font-family: 'font Awesome 5 Free';
    content: "\f068";
    float: right;
    color: #121518;
    font-size: 12px;
    font-weight: 900;
    transition: .3s;
}

.faqs .card-body {
    padding: 15px 0 0 0;
    font-size: 16px;
    border: none;
    background: #ffffff;
}

.faqs a.btn {
    position: relative;
    margin-top: 15px;
    padding: 15px 35px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #f1926f;
    border: 2px solid #f1926f;
    border-radius: 0;
    background: #37297c;
    transition: .3s;
}

.faqs a.btn:hover {
    color: #121518;
    background: #aa9166;
}


/*******************************/
/******* Testimonial CSS *******/
/*******************************/
.testimonial {
    position: relative;
    padding: 45px 0;
}

.testimonial .testimonials-carousel {
    position: relative;
    width: calc(100% + 30px);
    left: -15px;
    right: -15px;
}

.testimonial .testimonial-item {
    position: relative;
    margin: 0 15px;
    padding: 30px;
    background: #121518;
    overflow: hidden;
}

.testimonial .testimonial-item i {
    position: absolute;
    top: 0;
    right: 0;
    color: #333333;
    font-size: 60px;
    transform: rotate(45deg);
    z-index: 1;
}

.testimonial .testimonial-item .col-12 {
    margin-top: 20px;
}

.testimonial .testimonial-item img {
    width: 100%;
}

.testimonial .testimonial-item h2 {
    color: #aa9166;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
}

.testimonial .testimonial-item p {
    color: #999999;
    font-size: 16px;
    margin: 0;
}

.testimonial .owl-nav,
.testimonial .owl-dots {
    margin-top: 15px;
    text-align: center;
}

.testimonial .owl-dot {
    display: inline-block;
    margin: 0 5px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #aa9166;
}

.testimonial .owl-dot.active {
    background: #121518;
}


/*******************************/
/*********** Blog CSS **********/
/*******************************/
.blog {
    position: relative;
    width: 100%;
    padding: 45px 0;
}

.blog .blog-carousel {
    position: relative;
    width: calc(100% + 30px);
    left: -15px;
    right: -15px;
}

.blog .blog-item {
    position: relative;
    margin: 0 15px;
    background: #ffffff;
    overflow: hidden;
}

.blog .blog-page .blog-item {
    margin: 0 0 30px 0;
}

.blog .blog-item img {
    width: 100%;
    margin-bottom: 25px;
}

.blog .blog-item h3 {
    font-size: 25px;
    font-weight: 600;
}

.blog .blog-item .meta {
    padding: 0 0 15px 0;
}

.blog .blog-item .meta * {
    display: inline-block;
    margin: 0;
    padding: 0;
    font-size: 14px;
    color: #999999;
}

.blog .blog-item .meta i {
    margin: 0 2px 0 10px;
}

.blog .blog-item .meta i:first-child {
    margin-left: 0;
}

.blog .blog-item p {
    font-size: 16px;
}

.blog .blog-item a.btn {
    padding: 0;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #aa9166;
    transition: .3s;
}

.blog .blog-item a.btn i {
    margin-left: 10px;
}

.blog .blog-item a.btn:hover {
    color: #121518;
}

.blog .owl-nav,
.blog .owl-dots {
    margin-top: 15px;
    text-align: center;
}

.blog .owl-dot {
    display: inline-block;
    margin: 0 5px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #aa9166;
}

.blog .owl-dot.active {
    background: #121518;
}

.blog .pagination .page-link {
    color: #121518;
    border-radius: 0;
    border-color: #aa9166;
}

.blog .pagination .page-link:hover,
.blog .pagination .page-item.active .page-link {
    color: #121518;
    background: #aa9166;
}

.blog .pagination .disabled .page-link {
    color: #999999;
}


/*******************************/
/********* Timeline CSS ********/
/*******************************/
.timeline {
    position: relative;
    padding: 45px 0;
}

.timeline .timeline-start {
    position: relative;
    width: calc(100% + 30px);
    left: -15px;
    right: -15px;
}

.timeline .timeline-container {
    padding: 0 15px;
    position: relative;
    background: inherit;
    width: 50%;
    margin-top: -5px;
}

.timeline .timeline-container.left {
    left: 0;
}

.timeline .timeline-container.right {
    left: 50%;
}

.timeline .timeline-container::before {
    content: '';
    position: absolute;
    width: 15px;
    height: 5px;
    background: #aa9166;
    z-index: 1;
}

.timeline .timeline-container.left::before {
    top: 0;
    right: 0;
}

.timeline .timeline-container.right::before {
    bottom: 0;
    left: 0;
}

.timeline .timeline-container::after {
    content: '';
    position: absolute;
    width: 15px;
    height: 5px;
    background: #aa9166;
    z-index: 1;
}

.timeline .timeline-container.left::after {
    bottom: 0;
    right: 0;
}

.timeline .timeline-container.right::after {
    top: 0;
    left: 0;
}

.timeline .timeline-container:first-child::before,
.timeline .timeline-container:last-child::before {
    display: none;
}

.timeline .timeline-container .timeline-content {
    padding: 30px;
    background: #121518;
    position: relative;
    border-right: 5px solid #aa9166;
}

.timeline .timeline-container.right .timeline-content {
    border-left: 5px solid #aa9166;
}

.timeline .timeline-container .timeline-content h2 {
    margin-bottom: 15px;
    font-size: 22px;
    font-weight: 600;
    color: #aa9166;
}

.timeline .timeline-container .timeline-content h2 span {
    display: inline-block;
    margin-right: 15px;
    padding: 5px 10px;
    color: #121518;
    background: #aa9166;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
}

.timeline .timeline-container .timeline-content p {
    margin: 0;
    font-size: 16px;
    color: #999999;
}

@media (max-width: 767.98px) {
    .timeline .timeline-container {
        width: 100%;
        margin-top: 0;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 30px;
    }

    .timeline .timeline-container:last-child {
        padding-bottom: 0;
    }

    .timeline .timeline-container.right {
        left: 0%;
    }

    .timeline .timeline-container.left::after,
    .timeline .timeline-container.right::after,
    .timeline .timeline-container.left::before,
    .timeline .timeline-container.right::before {
        width: 5px;
        height: 35px;
        left: 15px;
    }

    .timeline .timeline-container.left .timeline-content,
    .timeline .timeline-container.right .timeline-content {
        border-left: 5px solid #aa9166;
        border-right: none;
    }
}


/*******************************/
/******** Portfolio CSS ********/
/*******************************/
.portfolio {
    position: relative;
    padding: 45px 0;
    background: #ffffff;
}

.portfolio #portfolio-flters {
    padding: 0;
    margin: -15px 0 25px 0;
    list-style: none;
    font-size: 0;
    text-align: center;
}

.portfolio #portfolio-flters li,
.portfolio .load-more .btn {
    cursor: pointer;
    display: inline-block;
    margin: 5px;
    padding: 8px 15px;
    color: #aa9166;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 0;
    background: #121518;
    border: 2px solid #aa9166;
    transition: .3s;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
    background: #aa9166;
    color: #121518;
}

.portfolio .load-more {
    text-align: center;
}

.portfolio .load-more .btn {
    padding: 15px 35px;
    font-size: 16px;
    transition: .3s;
}

.portfolio .load-more .btn:hover {
    color: #121518;
    background: #aa9166;
}

.portfolio .portfolio-item {
    position: relative;
    margin-bottom: 30px;
    overflow: hidden;
    transition: .5s;
}

.portfolio .portfolio-item:hover {
    margin-top: -10px;
}

.portfolio .portfolio-wrap {
    position: relative;
    width: 100%;
}

.portfolio img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.portfolio figure {
    position: absolute;
    padding: 30px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
    background: linear-gradient(rgba(0, 0, 0, .0), rgba(0, 0, 0, .0), rgba(0, 0, 0, 1) 90%);
}

.portfolio figure p {
    position: relative;
    margin-bottom: 5px;
    padding-bottom: 5px;
    color: #ffffff;
    font-size: 18px;
}

.portfolio figure p::after {
    position: absolute;
    content: "";
    width: 50px;
    height: 2px;
    left: 0;
    bottom: 0;
    background: #ffffff;
}

.portfolio figure a {
    font-family: 'EB Garamond', serif;
    color: #aa9166;
    font-size: 25px;
    font-weight: 600;
    font-style: italic;
    transition: .3s;
}

.portfolio figure a:hover {
    text-decoration: none;
    color: #ffffff;
}

.portfolio figure span {
    position: relative;
    color: #999999;
    font-size: 16px;
}


/*******************************/
/********* Contact CSS *********/
/*******************************/
.contact {
    position: relative;
    width: 100%;
    padding: 45px 0 15px 0;
}

.contact .contact-info {
    position: relative;
    margin-bottom: 30px;
    width: 100%;
    padding: 30px;
    background: #37297c;
}

.contact .contact-item {
    position: relative;
    margin-bottom: 30px;
    padding: 30px;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    border: 2px solid #f1926f;
}

.contact .contact-item i {
    display: inline-block;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #f1926f;
    border: 2px solid #f1926f;
}

.contact .contact-text {
    position: relative;
    width: auto;
    padding-left: 15px;
}

.contact .contact-text h2 {
    color: white;
    font-size: 25px;
    font-weight: 600;
}

.contact .contact-text p {
    margin: 0;
    color: white;
    font-size: 16px;
}

.contact .contact-item:last-child {
    margin-bottom: 0;
}

.contact .contact-form {
    position: relative;
    margin-bottom: 30px;
}

.contact .contact-form input {
    height: 60px;
    border-radius: 0;
    border-width: 2px;
    border-color: #121518;
}

.contact .contact-form textarea {
    height: 190px;
    border-radius: 0;
    border-width: 2px;
    border-color: #121518;
}

.contact .contact-form .btn {
    padding: 16px 35px;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff;
    background: #3a2679;
    border: 2px solid #3a2679;
    border-radius: 0;
    transition: .3s;
}

.contact .contact-form .btn:hover {
    color: #121518;
    background: #ea3322;
}


/*******************************/
/******* Single Page CSS *******/
/*******************************/
.single {
    position: relative;
    width: 100%;
    padding: 45px 0 65px 0;
}

.single img {
    margin: 10px 0 15px 0;
}

.single .table,
.single .list-group {
    margin-bottom: 30px;
}

.single .ul-group {
    padding: 0;
    list-style: none;
}

.single .ul-group li {
    margin-left: 2px;
}

.single .ul-group li::before {
    position: relative;
    content: "\f0da";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    margin-right: 10px;
}

.single .ol-group {
    padding-left: 18px;
}


/*******************************/
/********** Newsletter *********/
/*******************************/
.newsletter {
    position: relative;
    max-width: 900px;
    margin: 45px auto -140px auto;
    padding: 30px 15px;
    background: #aa9166;
    z-index: 1;
}

.newsletter .section-header {
    margin-bottom: 25px;
}

.newsletter .section-header h2::before,
.newsletter .section-header h2::after {
    border-color: #ffffff;
}

.newsletter .form {
    position: relative;
    max-width: 400px;
    margin: 0 auto;
}

.newsletter input {
    height: 50px;
    border: 2px solid #121518;
    border-radius: 0;
}

.newsletter .btn {
    position: absolute;
    top: 5px;
    right: 5px;
    height: 40px;
    padding: 8px 20px;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    color: #aa9166;
    background: #121518;
    border-radius: 0;
    border: 2px solid #aa9166;
    transition: .3s;
}

.newsletter .btn:hover {
    color: #121518;
    background: #aa9166;
}


/*******************************/
/********* Footer CSS **********/
/*******************************/
.footer {
    position: relative;
    margin-top: 45px;
    padding-top: 94px;
    background: black;
    color: #ffffff;
}

.footer .footer-about,
.footer .footer-link,
.footer .footer-contact {
    position: relative;
    margin-bottom: 45px;
    color: white;
}

.footer .footer-about h2,
.footer .footer-link h2,
.footer .footer-contact h2 {
    position: relative;
    margin-bottom: 30px;
    font-size: 25px;
    font-weight: 600;
    color: #fa491c;
}

.footer .footer-link a {
    display: block;
    margin-bottom: 10px;
    color: white;
    transition: .3s;
}

.footer .footer-link a::before {
    position: relative;
    content: "\f105";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    margin-right: 10px;
}

.footer .footer-link a:hover {
    color: #f1926f;
    letter-spacing: 1px;
}

.footer .footer-contact p i {
    width: 25px;
}

.footer .footer-social {
    position: relative;
    margin-top: 20px;
}

.footer .footer-social a {
    display: inline-block;
}

.footer .footer-social a i {
    margin-right: 15px;
    font-size: 18px;
    color: #f1926f;
}

.footer .footer-social a:last-child i {
    margin: 0;
}

.footer .footer-social a:hover i {
    color: #999999;
}

.footer .footer-menu .f-menu {
    position: relative;
    padding: 15px 0;
    background: #000000;
    font-size: 0;
    text-align: center;
}

.footer .footer-menu .f-menu a {
    color: #999999;
    font-size: 16px;
    margin-right: 15px;
    padding-right: 15px;
    border-right: 1px solid rgba(255, 255, 255, .3);
}

.footer .footer-menu .f-menu a:hover {
    color: #aa9166;
}

.footer .footer-menu .f-menu a:last-child {
    margin-right: 0;
    padding-right: 0;
    border-right: none;
}


.footer .copyright {
    padding: 30px 15px;
}

.footer .copyright p {
    margin: 0;
    color: #999999;
}

.footer .copyright .col-md-6:last-child p {
    text-align: right;
}

.footer .copyright p a {
    color: #f1926f;
    font-weight: 500;
    letter-spacing: 1px;
}

.footer .copyright p a:hover {
    color: #ffffff;
}

@media (max-width: 768px) {
    .footer .copyright p,
    .footer .copyright .col-md-6:last-child p {
        margin: 5px 0;
        text-align: center;
    }
}

.img_client {
    width: 50%;
    margin: 17px 0px 0px 40px;
}

.card_1 {
    /*box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;*/
}

.img1_client {
    width: 88%;
    margin: 9px 0px 0px 0px;
}

.img2_client {
    width: 88%;
    margin: -9px 0px 0px 0px;
}

.img3_client {
    width: 92%;
    margin: 38px 0px 0px 10px;
}

.logoo {
    border-radius: 100px;
    width: 56px;
    height: 56px;
}

.mail {
    font-size: 14px;
    color: white;
}

.card_2 {
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    background: white;
    border-radius: 10px;
}

@media only screen and (max-width:600px) {

    .badges[_ngcontent-ikt-c128] {
        width: 27px;
        padding: -10px;
        margin-top: 0%;
    }

    .cardn[_ngcontent-ikt-c128] {
        border: 1px solid rgba(255, 0, 0, 0.3490196078);
        padding: 1px 2px;
        border-radius: 5px;
        height: 13%;
        margin-top: 5%;
    }

    .img1_client {
        width: 55%;
        margin: 3px 0px 0px 19px;
    }

    .img_client {
        width: 38%;
        margin: 6px 0px 0px 40px;
    }

    .col-sx-6 {
        width: 50%;
        padding: 10PX;
    }

    .img3_client {
        width: 79%;
        margin: 14px 0px 0px 10px;
    }

    .img2_client {
        width: 65%;
        margin: -9px 0px 2px 21px;
    }

    .top-bar {
        position: relative;
        height: 1px;
        background: #ffffff;
    }

    .about {
        display: none;
    }

    .about1 {
    }
}

.about {
    display: contents;
}

.about1 {
    display: none;
}


.breadcrumb {
    background-color: rgb(11 173 223 / 0%);
    color: black;
}

.Home a {
    color: #fffbfb !important;
    letter-spacing: 0.5px;
}

.banner_heading {
    font-size: 27px;
    color: white;
    font-weight: 600;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.banner_image {
background-image: url('assets/payertax/banners/download.jpg');
    background-attachment: initial;
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 1rem;
    padding: 16px;
}

.content_image {
    min-height: 200px;
    background-attachment: initial;
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
}

.button-71 {
    background-color: #0078d0;
    border: 0;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    /* font-family: system-ui, -apple-system, system-ui, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif; */
    font-size: 16px;
    font-weight: 600;
    outline: 0;
    padding: 16px 21px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 50%;
}

.button-71:before {
    background-color: initial;
    background-image: linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%);
    border-radius: 125px;
    content: "";
    height: 50%;
    left: 4%;
    opacity: .5;
    position: absolute;
    top: 0;
    transition: all .3s;
    width: 92%;
}

.button-71:hover {
    box-shadow: rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .1) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px;
    transform: scale(1.05);
}

@media (min-width: 768px) {
    .button-71 {
        padding: 16px 48px;
    }
}
@media only screen and (max-width:600px) {
    .logoo {
        display: none;
    }

    .carousel {
        position: relative;
        width: 100%;
        height: calc(58vh - 206px);
        margin: 0 auto;
        text-align: center;
        overflow: hidden;
    }

    .card_2 {
        box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
        border-radius: 20px;
        border-left: 3px solid #ef4411;
        height: 31rem;
        margin-top: 5%;
    }

    .banner_image {
        /*background-image: url('assets/lawyersimages/capture.jpg');*/
        background-attachment: initial;
        background-position: right;
        background-repeat: no-repeat;
        background-size: cover;
        margin-top: 0rem;
        padding: 16px;
    }

    .modal-content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 103%;
        color: var(--bs-modal-color);
        pointer-events: auto;
        background-color: var(--bs-modal-bg);
        background-clip: padding-box;
        border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
        border-radius: var(--bs-modal-border-radius);
        outline: 0;
        margin-left: 0%;
    }
}

.card {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 10px;
}

changeTagColor {
    background-image: linear-gradient(to top, #fdb31a #fdb31a);
    border: none;
}

.BackGroung {
    background-color: black !important;
    color: #ede4e4;
    border: none;
}
